import ProgressSpinner from '../progress_spinners/ProgressSpinner'

import { buttonLayout } from '~/lib/utils/button'

// Action buttons accept a click handler

const buttonClasses =
  'block w-full rounded-md cursor-pointer whitespace-pre-wrap py-3.7 px-2.5 text-center text-sm font-bold md:py-4 md:text-lg'

interface ActionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  subtitle?: string
  showProgressIndicator?: boolean
}

// Primary buttons rendered in the brand red color
export function PrimaryActionButton({
  showProgressIndicator,
  title,
  subtitle,
  ...buttonProps
}: ActionButtonProps) {
  return (
    <button
      className={`${buttonClasses} flex flex-row items-center justify-center bg-orange text-white transition-opacity duration-500 hover:opacity-90 disabled:cursor-default disabled:opacity-75 disabled:hover:bg-orange`}
      {...buttonProps}
    >
      {showProgressIndicator ? <ProgressSpinner /> : buttonLayout(title, subtitle)}
    </button>
  )
}

// Secondary buttons render with a slight transparent white overlay
export function SecondaryActionButton({ onClick, title, subtitle }: ActionButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`${buttonClasses} bg-white/[.38] text-white transition-opacity duration-500`}
    >
      {buttonLayout(title, subtitle)}
    </button>
  )
}

// Tertiary buttons render with a dark background and outlined button shape
export function TertiaryActionButton({ onClick, title, subtitle }: ActionButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`${buttonClasses} border border-white text-white transition-colors duration-500 hover:bg-black-alt`}
    >
      {buttonLayout(title, subtitle)}
    </button>
  )
}

// No background
export function NoBackgroundActionButton({ onClick, title, subtitle }: ActionButtonProps) {
  return (
    <button onClick={onClick} className={`text-white ${buttonClasses}`}>
      {buttonLayout(title, subtitle)}
    </button>
  )
}
